.block-slider {
    --width-slide: 100%;
    --height: 75vh;
    
    @media (max-width: $smartphone) {
        --height: 65vh;
        --width-slide: 80vw;
    }
}

.block-slider {
    width: var(--width);
    margin: var(--margin) 0;
    padding: 0;
    user-select: none;
    background: var(--bg);
    color: var(--color);

    @include font-text();
    
    &__slider {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    
        > .holder {
            order: 1;
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
        }
    }

    &__item {
        position: relative;
        flex: 0 0 var(--width-slide);
        width: var(--width-slide);
        overflow: hidden;

        .info {
            margin-bottom: var(--padding-xs);
            display: flex;
            flex-direction: column;

            span:first-child {
                margin-bottom: var(--padding-xxxs);
            }
        }

        img {
            height: var(--height);
            width: 100%;
            position: relative;
            overflow: hidden;

            object-fit: contain;
            object-position: center;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 var(--padding-h);
        margin-top: var(--padding-xxs);
        
        button {
            @include basic-a();
            @include font-text();
            pointer-events: all;
            background-color: transparent;
            padding: 0;
        }

        @include isCursor() {
            button:hover {
                color: var(--light-grey);
            }
        }

        .prev-next {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .info {
            > div {
                --lineHeight: calc(1.3 * 1em);
                overflow: hidden;
                height: var(--lineHeight);

                > div {
                    @include font-text(1);
                    display: block;
                    height: var(--lineHeight);
                    transform: translateY(calc(-1 * var(--index) * var(--lineHeight)));
                }
            }
        }
    }
    
    @media (min-width: $smartphone) {
        display: flex;
        flex-direction: column;

        &__controls {
            order: 1;
            margin-bottom: var(--padding-xs);
        }

        &__slider {
            order: 2;
        }
    }

    @media (max-width: $smartphone) {
        &__slider > .holder {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            gap: var(--padding-h);

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &__item {
            scroll-snap-align: start;
            
            &:first-child,
            &:last-child {
                flex: 0 0 calc(var(--padding-h) - var(--gap));
                width: calc(var(--padding-h) - var(--gap));
            }
        }
    }
}
