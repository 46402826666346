.block-prensa-y-premios {
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-s);
    --gap: var(--padding-xs);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
    }
}

.block-prensa-y-premios {
    padding: var(--padding-v) var(--padding-h);

    @include font-text();
    
    a {
        @include basic-a();
        color: var(--primary-color);
    }

    > * {
        display: flex;
        gap: var(--gap);
    } 

    > *:not(:last-child) {
        margin-bottom: var(--gap);
    }

    .row {
        > div:first-child {
            width: rem(80);
        }
    }

    li {
        height: calc(1.2 * 1em);
        display: block;
    }

    .block-project-default + & {
        margin-top: calc(-1 * var(--padding-xs));
    }
    
    @media (min-width: $smartphone) {
        .block-project-default + & {
            margin-top: calc(-1 * var(--padding-s));
        }

        .row {
            > div:first-child {
                width: rem(120);
            }
        }
    }
}
