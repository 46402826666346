.block-services {
    --padding-v: var(--padding-xl-em);
    --padding-h: var(--padding-s);
    --bg: var(--primary-color);
    --color: var(--primary-bg);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m-em);
        --padding-h: var(--padding-xs);
    }
}

.block-services {
    @include font-text();
    padding: var(--padding-v) var(--padding-h);
    position: relative;
    color: var(--color);
    background-color: var(--bg);

    button {
        padding: 0;
        background-color: transparent;
        color: var(--dark-grey);
        text-align: left;

        &.--active {
            color: var(--color);
            pointer-events: none;
        }

        &.--hover {
            color: var(--color);
        }
    }

    .service-indexes,
    .service-names {
        @include font-construct();
    }

    .service-indexes {
        display: flex;
        gap: var(--padding-xxxs);
    }

    .service-names {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__text {
        > * {
            max-width: rem(410);
        }
    }

    .content {
        .service:not(.--active),
        .img:not(.--active),
        .wysiwyg:not(.--active) {
            display: none;
        }

        .sections {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
    }

    @media (max-width: $smartphone) {
        &__text {
            margin-bottom: var(--padding-s); 
        }

        .service-names {
            margin-top: var(--padding-xxs);
            gap: var(--padding-xxs);
        }

        &__images {
            margin-top: var(--padding-xs); 
        }

        .content {
            margin-top: var(--padding-xs);
        }

        .sections {
            margin-bottom: var(--padding-xs);
            order: 1;
        }

        .gallery {
            order: 2;
        }

        .texts { 
            order: 3;
            margin-top: var(--padding-xs);
        } 

        .service {
            display: flex;
            flex-direction: column;
        }
    }

    @media (min-width: $smartphone) {
        &__text {
            margin-bottom: var(--padding-xl-em); 
        }

        .service-indexes {
            margin-bottom: .2em;
        }
        
        .service-names {
            gap: .2em;
        }

        .content {
            margin-top: var(--padding-v);
            min-height: rem(300);

            @include isCursor() {
                button:hover {
                    color: var(--color);
                }
            }

            .service {
                min-height: rem(260);
            }

            .gallery { flex: 0 0 rem(540); }
            .images { width: rem(540); }

            .service,
            .gallery {
                display: flex;
                gap: var(--padding-xs);
            }

            .img,
            .sections {
                flex: 0 0 rem(260);
            }

            .texts {
                flex: 0 0 rem(400);
            }
        }
    }
}
