#Interface__Canvas {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    @include z-index($z-index-interface);
    pointer-events: none;

    &.--behind {
        @include z-index($z-index-bg);
    }
}

/*
#InterfaceLogo {
  --x:0;
  --y:0;
  --opacity: 0;
  --height: 400;
  --scale: calc(var(--height)/200);

  position: fixed;
  @include z-index($z-index-interface);
  top:var(--y);
  left:var(--x);
  width: 707.52px;
  height: 200px;
  opacity: var(--opacity);
  pointer-events: none;

  clip-path: url(#svgTextPath);
  background: rgba(255,255,255,0.01);
  backdrop-filter: blur(.2vw);

  transform: scale3d(var(--scale), var(--scale), 1);
  transform-origin: 0 0;
  
  @media (max-width: $smartphone) {
    display: none;
  }
}*/

body > canvas {
  position: fixed;
  @include z-index(0);
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
}
