//↗ → ← ↖

@mixin link-underline() {
    --scale: 0;
    --ease: var(--ease-out-cubic);
    --time: .6s;
    --offset: 0px;

    @include basic-a();

    background-color: transparent;
    text-align: left;
    padding: 0;
    
    span {
        position: relative;
        padding-left: var(--offset);
    }
        
    span:before {
        @include pseudo-element-absolute('');
        bottom: .06em;
        left: var(--offset);
        width: calc(100% - var(--offset));
        height: rem(1);
        background-color: currentColor;
        transform: scaleX(var(--scale));
        transform-origin: right center;
       // transition: transform var(--time) var(--ease);
    }

    &:hover span {
        --scale: 1;
     
        &:before {
            transform-origin: left center;
        }
    }

    &.--is-active span,
    &.--active span,
    &.__link-active span,
    &[aria-current="page"] {
        --scale: 1;
        pointer-events: none;
    }
}

.link-underline {
    @include link-underline();
}
