@use "sass:math";

.next-project {
    --bg: var(--biga-grey);
    --color: var(--primary-color);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);
    --gap: var(--padding-xs);
    
    @media (max-width: $smartphone) {
        --gap: var(--padding-xxs);
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }
}

.next-project {
    display: flex;

    padding: var(--padding-v) var(--padding-h);
    background-color: var(--bg);
    color: var(--color);
    
    @include font-text();

    a {
        @include font-text();
        @include basic-a();
        color: var(--color);
    }

    .gallery {
        display: flex;
        gap: var(--gap);
    }

    .next {
        text-align: right;
    }

    @media (max-width: $smartphone) {
        flex-wrap: wrap;

        > *:not(:last-child) {
            flex: 0 0 50%;
        }

        > *:last-child {
            flex: 0 0 100%;
        }

        .gallery {
            margin-top: var(--gap);

            > * {
                flex-grow: 1;
            }

            > *:first-child {
                flex-grow: 2;
            }
        }
    }
    
    @media (min-width: $smartphone) {
        gap: var(--gap);

        .info {
            order: 1;
            flex: 0 0 rem(260);

            .abstract {
                margin-top: var(--gap);
            }
        }
        
        .next {
            order: 3;
            flex: 0 0 rem(120);
        }
        
        .gallery {
            order: 2;
            flex-grow: 1;

            > * {
                flex: 0 0 rem(260);
            }
            
            > *:last-child {
                flex: 0 0 rem(400);
            }
        }
    }
}