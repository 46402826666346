.block-project-default {
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-xs);
    --gap: var(--padding-xs);
    
    // @media (max-width: $smartphone) {
    //     --padding-v: var(--padding-xs);
    //     --padding-h: var(--padding-xs);
    //     --gap: var(--padding-xs);
    // }
}

.block-project-default {
    margin: var(--padding-v) 0;
    padding: 0 var(--padding-h);

    @include font-text();
    
    @media (max-width: $smartphone) {
        > *:not(:last-child) {
            margin-bottom: var(--gap);
        }
        
        &.--inverted {
            display: flex;
            flex-direction: column-reverse;

            > *:not(:last-child) {
                margin-bottom: 0;
                margin-top: var(--gap);
            }
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        gap: var(--gap);

        > * {
            flex-grow: 1;
        }
        
        &.--inverted {
            flex-direction: row-reverse;
        }

        // .mask {
        //     figure, img {
        //         position: absolute;
        //         left: 0;
        //         top: 0;
        //         width: 100%;
        //         height: 100%;
        //         object-fit: cover;
        //     }
        // }

        p {
            max-width: var(--max-text-box);
        }
    }
}
