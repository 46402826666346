.widget-projects {
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);
    --icon-height: #{rem(183)};
    --icon-width: #{rem(650)};
    --thumb-height: #{rem(40)};
    --thumb-width: #{rem(30)};
    --cols: 10;
    --rows: 3;
    --row-height: #{rem(260)};
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
        --image-height: #{rem(170)};
        --thumb-height: #{rem(20)};
        --thumb-width: #{rem(20)};
        --cols: 3;
        --rows: 4;

        .--1 {
            --image-height: #{rem(170)};
            --image-width: #{rem(170)};
        }

        .--2 {
            --image-height: #{rem(160)};
            --image-width: #{rem(300)};
        }

        .--3 {
            --image-height: #{rem(120)};
            --image-width: #{rem(170)};
        }

        .--4 {
            --image-height: #{rem(90)};
            --image-width: #{rem(90)};
        }

        .--pos-1 {
            align-items: flex-start;
        }
        
        .--pos-2 {
            align-items: center;
        }
        
        .--pos-3 {
            align-items: flex-end;
        }
    }

    @media (min-width: $smartphone) {
        .--1 {
            --image-height: #{rem(260)};
            --image-width: #{rem(260)};
        }

        .--2 {
            --image-height: #{rem(220)};
            --image-width: #{rem(400)};
        }

        .--3 {
            --image-height: #{rem(160)};
            --image-width: #{rem(260)};
        }

        .--4 {
            --image-height: #{rem(120)};
            --image-width: #{rem(120)};
        }
        
        .--pos-1 {
            --right: auto;
            --left: var(--padding-h);
        }
        
        .--pos-2 {
            --right: auto;
            --left: #{rem(310)};
        }
        
        .--pos-3 {
            --right: auto;
            --left: #{rem(450)};
        }
        
        .--pos-4 {
            --left: auto;
            --right: #{rem(170)};
        }
    }
}

.widget-projects {
    position: relative;
    @include font-text();

    // #LogoBiga {
    //     opacity: 1;
    // }

    a {
        display: block;
        @include basic-a();

        &:not([href]) {
            opacity: 0;
        }
    }

    &__visor {
        > div {
            display: flex;
            background-color: var(--white);
            padding: var(--padding-v) var(--padding-h);
            position: relative;
            color: var(--black);
        }
        
        a {
            color: var(--black);
        }
    }

    .code,
    .title {
        @include font-text();
        height: calc(1.2 * 1em);
    }

    .thumbnails {
        display: grid;
        grid-template-columns: repeat(var(--cols), var(--thumb-width));
        grid-template-rows: repeat(var(--rows), var(--thumb-height));
        gap: var(--padding-xxs);

        li {
            height: var(--thumb-height);
            width: var(--thumb-width);
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
            }

            &.--current::after {
                background-color: var(--pink);
            }

            img {
                pointer-events: initial;
                cursor: pointer;
            }
        }
    }
    
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    
    .visor {
        flex-grow: 1;
        position: relative;

        img {
            @include z-index(0);
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: contain;
        }

        img.--show {
            @include z-index(1);
        }
    }

    &__image {
        position: relative;
        @include z-index(1);
    }

    &__image {
        pointer-events: none !important;

        > div {
            height: var(--image-height);
            width: var(--image-width);
            overflow: hidden;
        }
    }

    @media (max-width: $smartphone) {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);

        display: flex;
        flex-direction: column;

        &__visor {
            > div {
                gap: var(--padding-xxs);
                height: rem(150);
            }

            .info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 0 0 rem(80);
            }

            .visor {
                width: rem(170);
            }
        }

        &__image {
            display: flex;
            padding-right: var(--padding-h);
            padding-bottom: var(--padding-v);
            
            &:first-child {
                padding-left: var(--padding-h);
            }
        }

        &__images {
            flex-grow: 1;
            display: flex;
            justify-content: flex-start;
            margin-top: rem(160);
        }
    }

    @media (min-width: $smartphone) {
        height: 100vh;

        > &__visor {
            @include z-index(0);
            left: 48.7%;
            position: absolute;
            top: 0;
            height: 100vh;
            width: 51.3%;
            
            > div {
                flex-direction: column;
                gap: var(--padding-xs);
                height: calc(100vh - var(--padding-v));
            }

            a {
                position: absolute;
                top: var(--padding-v);
                right: var(--padding-h);
            }
        }

        &__logo {
            display: flex;
            align-items: flex-end;
            padding: var(--padding-v) var(--padding-h);
            padding-right: var(--padding-xs);
            height: 100%;
            width: 48.7%;
            @include z-index(1);
            position: relative;

            .icon {
                height: auto;
                width: 100%;
            }
        }

        &__images {
            @include z-index(2);

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            padding: 125vh var(--padding-h) var(--padding-v);
            pointer-events: none;

            * {
                user-select: none;
            }
        }
        
        &__image {
            height: var(--image-height);
            margin-bottom: var(--padding-l);
            
            &:last-child {
                height: 20vh;
            }
            
            > div {
                position: absolute;
                top: 0;
                left: var(--left);
                right: var(--right);

                img {
                    @include z-index(1);
                    width: 100% !important;
                    height: 140% !important;
                    opacity: 0.65;
                    position: relative;
                    filter: contrast(1.35) saturate(1.3);
                }

                &::before {
                    @include z-index(0);
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    backdrop-filter: blur(.5vw);
                }
            }
        }
    }
}
