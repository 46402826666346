.block-texto-cuadrada-landscape {
    > div:last-child {
        > div:not(:last-child) {
            margin-bottom: var(--gap);
        }
    }

    // .--1 {
    //     .mask {
    //         @include aspect-ratio(1, 1);
    //     }
    // }

    // .--1,
    // .--2 {
    //     figure, img {
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //         padding: 0;
    //     }
    // }
    
    @media (max-width: $smartphone) {
        .--1 {
            width: 50%;
        }

        // .--2 {
        //     .mask {
        //         @include aspect-ratio(350, 170);
        //     }
        // }

        &.--inverted > div:nth-child(2) {
            display: flex;
            justify-content: flex-end;
        }
    }

    @media (min-width: $smartphone) {
        .--1 {
            width: rem(260);
        }

        .--2 {
            width: rem(680);

            // .mask {
            //     @include aspect-ratio(680, 383);
            // }
        }

        > div:last-child {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
