.block-project-list {
    --padding-v: var(--padding-xxl);
    --padding-v-bottom: var(--padding-l);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
    }
}

.block-project-list {
    padding: var(--padding-v) 0 var(--padding-v-bottom);

    &__billboard {
        display: flex;
        justify-content: flex-end;
        padding: 0 var(--padding-h);

        .icon {
            display: block;
            width: rem(650);
            height: auto;
        }
    }

    .gallery {
        gap: var(--padding-xxs);
        display: flex;
    }

    .project-card {
        @include basic-a();
        @include font-text();

        display: block;
        color: var(--primary-color);
        padding: 0 var(--padding-h);

        .--pos-1 {
            order: 1;
        }
        
        .--pos-2 {
            order: 2;
        }

        .--pos-3 {
            order: 3;
        }

        .--pos-4 {
            order: 4;
        }

        .--pos-5 {
            order: 5;
        }

        .--pos-6 {
            order: 6;
        }
    }

    &__categories {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 0 var(--padding-h);
        margin-bottom: var(--padding-l);

        a {
            @include basic-a();
            @include font-text();
            color: var(--primary-color);
            height: calc(1.2 * 1em);
            display: inline-block;
        }

        [aria-current="page"] {
            color: var(--black);
        }
    }

    @include isCursor() {
        .project-card:hover {
            background: var(--white);
        }
    }

    @media (max-width: $smartphone) {
        padding-top: calc(var(--padding-v) + var(--header-height));

        li:nth-child(odd) {
            .project-card {
                background: var(--white);
            }
        }
            
        .project-card {
            padding: var(--padding-xs) var(--padding-h);

            .info {
                margin-bottom: var(--padding-xs);
                display: flex;
                justify-content: space-between;
            }
            
            .--1 {
                flex-grow: 1;
            }
            
            .--2,
            .--3,
            .--4,
            .--5 {
                flex-grow: 1;
            }
        }

        &__categories {
            margin-bottom: var(--padding-m);

            a {
                display: block;
            }
        }
    }

    @media (min-width: $smartphone) {
        &__billboard {
            margin-bottom: var(--padding-l);
        }

        .project-card {
            --gap: var(--padding-xs-em);
            --width-info: #{rem(340)};
            --width-container: calc(100% - var(--width-info) - var(--gap) * 5);

            padding: var(--padding-s) var(--padding-h);
            gap: var(--gap);
            display: flex;
            
            // .gallery {
            //     flex-grow: 1;
            //     gap: var(--padding-xs);
            // }

            .info {
                flex: 0 0 var(--width-info);

                .abstract {
                    margin-top: var(--padding-xs);
                }
            }
            
            .--1 {
                flex: 0 0 calc(var(--width-container) * .10);
            }

            .--2 {
                flex: 0 0 calc(var(--width-container) * .15);
            }

            .--3 {
                flex: 0 0 calc(var(--width-container) * .20);
            }

            .--4 {
                flex: 0 0 calc(var(--width-container) * .25);
            }
            .--5 {
                flex: 0 0 calc(var(--width-container) * .30);
            }
        }
    }
}
