html {
    width: 100%;
    height: 100%;
}

body {
    width: 100vw;
    min-height: 100%;
    color: var(--primary-color);
    background: var(--primary-bg);

    > video {
        visibility: hidden;
        pointer-events: none;
    }
    
    main {
        width: 100%;
    }
    
    &:not(.__scroll-manual) {
        overflow: hidden;
        height: 100vh;

        .wrap {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;

            @include z-index($z-index-wrap);
        }
    }
    
    &.__scroll-manual {
        &:not(.__noScroll) {
            overflow-y: scroll;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
        }
        
        &.__noScroll {
            overflow: hidden;
            overscroll-behavior-y: none;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            max-height: 100%;
        }

        .wrap {
            position: relative;
            @include z-index(1);
        }
    }

    &.__scroll-axis-x {
        max-height: 100%;
    }
}

#Main.--loading figure {
    background-color: var(--primary-color);
    transition: background-color 0s 0s;
}
#Main figure {
    background-color: transparent;
    transition: background-color 0s .5s;
}
#Main.--loading img,
#Main.--loading video {
    opacity: 0;
    transition: none;
}

#Main img,
#Main video {
    transition: opacity .2s .3s;
}

.icon {
    display: inline-block;

    svg {
        height: 100%;
        width: 100%;
        display: block;
    }
}

.line-parent {
    overflow: hidden;
}

*[aria-current="page"] {
    pointer-events: none;
    text-decoration: none;
}

button[disabled] {
    pointer-events: none;
    opacity: .3;
}

// Tweakpane
.tp-dfwv {
    @include z-index($z-index-tweakpane);
}

#LogoBi,
#LogoGa,
#LogoContact {
    path {
        fill: var(--primary-color);
    }
}

#LogoBiga {
    opacity: 0;
    display: block;
    
    @media (max-width: $smartphone) {
        display: none;
    }

    svg {
        width: 100%;
        height: auto;
    }
}

.wysiwyg {
    @include font-text();

    ul {
        li:before {
            content: '·';
            margin-right: rem(4);
        }
    }
}

.mask:empty {
    @media (max-width: $smartphone) {
        display: none !important;
    }
}