.free-logo {
    width: 100vw;
    height: 100vh;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
    justify-content: center;
    align-items: center;
    gap: 20px;

    padding: 10px;

    .logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    span {
        display: block !important;
        width: 100% !important;
    }

    svg {
        fill: black;
        width: 100%;
        height: auto;
    }

    #LogoBiga {
        opacity: 0;
        display: block;
        width: 100%;
    }
}