.not-found {
    --color: var(--primary-color);
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
    }
}

.not-found {
    @include font-text();

    padding: 25vh var(--padding-h) var(--padding-s);

    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        width: 100%;
        height: auto;
        display: block;

        path {
            fill: var(--pink);
        }
    }

    .text {
        margin-top: var(--padding-l);

        a {
            color: var(--color);
            display: block;
            text-align: center;
        }
    }

    @media (min-width: $smartphone) {
        .icon {
            height: rem(240);
            width: rem(620);
        }
    }
}
