#Header {
    --color: var(--aux-color);
    --bg: var(--header-bg);
    --padding-v-top: var(--padding-s);
    --padding-v-bottom: var(--padding-s);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-v-top: #{rem(150)};
        --padding-v-top-2: var(--padding-xs);
        --padding-v-bottom: var(--padding-xs);
        --padding-h: var(--padding-xs);

        [data-remove-smartphone] {
            display: none;
        }
    }
    
    @media (min-width: $smartphone) {
        [data-remove-desktop] {
            display: none;
        }
    }
}

#Header {
    @include font-text();
    @include z-index($z-index-header);

    color: var(--color);
    
    &.--with-bg {
        background-color: var(--bg);
    }

    &.--hide {
        display: none;
    }

    .toggle-menu {
        padding: 0;
        background-color: transparent;
        color: var(--primary-color)
    }

    a,
    button {
        @include basic-a();
        @include font-text();
        color: var(--color);
        height: calc(1.2 * 1em);
        display: inline-block;
    }

    button {
        padding: 0;
        background-color: transparent;
        text-align: left;
    }

    > nav {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .sub-nav {
            display: none;

            a { margin-right: em(3); }
        }

        [aria-current="page"] {
            &.--parent {
                display: none;
            }

            + .sub-nav {
                display: block;
            }
        }

        .--is-active,
        .--current-page,
        [aria-current="page"] {
            --color: var(--header-color);
        }
        
        @include isCursor() {
            a:hover,
            button:hover {
                --color: var(--header-color);
            }
        }
    }

    @media (max-width: $smartphone) {
        position: fixed;
        left: 0;
        top: 0; 
        padding: calc(var(--padding-v-top) + var(--padding-v-top-2)) var(--padding-h) var(--padding-v-bottom);
        
        .--home & {
            top: var(--padding-v-top);
            padding: var(--padding-v-top-2) var(--padding-h) var(--padding-v-bottom);
        }

        .icon--logo-footer {
            height: rem(48);
            width: rem(170);
        }
        
        .icon {
            pointer-events: none;
            margin-bottom: var(--padding-v-bottom);
            
            svg {
                fill: var(--primary-color);
                display: block;
                width: 100%;
            }
        }
        
        .icon--map {
            width: 100%;
            height: auto;
            display: none;
        }
        
        .--contact &,
        .--contacto & {
            --padding-v-top: #{rem(100)};
            width: 100%;
            
            .icon--logo-footer {
                display: none;
            }
            
            .icon--map {
                display: block;
            }
        }
        
        > div {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
    }

    @media (min-width: $smartphone) {
        position: fixed;
        left: 0;
        top: 0;

        padding: var(--padding-v-top) var(--padding-h) var(--padding-v-bottom);
    }
}
