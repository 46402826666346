@use "sass:math";

.widget-contact {
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);
    --icon-height: #{rem(311)};
    --icon-width: #{rem(1100)};
            
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
    }

    @media (min-width: $tabletLandscape) {
        --icon-height: #{rem(390)};
        --icon-width: #{rem(1380)};
    }
}

.widget-contact {
    position: relative;
    color: var(--color);
    @include font-text();

    a {
        @include font-text();
        @include basic-a();
        color: inherit;
    }

    &__text {
        padding: var(--padding-v) var(--padding-h);
        
        > * {
            max-width: rem(400);

            p:not(:last-child) {
                margin-bottom: var(--padding-xs);
            }
        }
    }

    &__contact {
        padding: var(--padding-v) var(--padding-h);
        
        > * {
            max-width: rem(155);
            display: block;
        }

        .contact {
            margin-top: var(--padding-xs);
        }
    }

    &__logo {
        position: relative;

        .layer {
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }

        .cell {
            height: calc(100% / var(--rows));
            width: calc(100% / var(--cols));
            overflow: hidden;
            position: relative;
            
            * {
                pointer-events: none;
            }
            
            &.--logo {
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border: 1px solid transparent;
                }

                &.--line {
                    &::after {
                        border-color: var(--white);
                    }
                }

                &.--active,
                &.--hover:not(.--active) {
                    > * {
                        opacity: 0;
                    }
                }
            }

            &.--map {
                > * {
                    opacity: 0;
                }

                &.--active,
                &.--hover:not(.--active) {
                    > * {
                        opacity: 1;
                    }
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        padding-top: calc(var(--padding-v) + var(--header-height));

        &__text {
            padding-top: 0;
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;

        $cols: 10;
        $rows: 3;
        --cols: #{$cols};
        --rows: #{$rows};

        &__logo {
            height: var(--icon-height);
            width: var(--icon-width);
            margin-top: rem(380);

            .icon {
                height: var(--icon-height);
                width: var(--icon-width);
            }

            $translate-x: math.div(100%, $cols);
            $translate-y: math.div(100%, $rows);

            .cell {
                @for $i from 0 through ($cols * $rows) {
                    &:nth-child(#{$i + 1}) {
                        $col: $i % $cols;
                        $row: floor(math.div($i, $cols));
                        $translate-x-value: $translate-x * $col;
                        $translate-y-value: $translate-y * $row;

                        .icon {
                            transform: translate(-$translate-x-value, -$translate-y-value);
                        }
                    }
                }
            }
        }

        &__text {
            @include z-index($z-index-header);
            
            position: absolute; 
            top: 0;
            left: calc(#{rem(590)} - var(--padding-h));
        }

        &__contact {
            @include z-index($z-index-header);
            
            position: absolute; 
            top: 0;
            right: calc(#{rem(135)} - var(--padding-h));
        }
    }

    @media (min-width: $tabletLandscape) and (orientation: landscape) {
        padding-bottom: var(--padding-v);
        min-height: 100vh;

        &__logo {
            margin-top: rem(200);
        }
    }
}
