.block-pequena-cuadrada-texto {
    > div:first-child {
        > div:not(:last-child) {
            margin-bottom: var(--gap);
        }
    }

    // .--2 {
    //     .mask {
    //         @include aspect-ratio(1, 1);
    //     }
    // }

    // .--1,
    // .--2 {
    //     figure, img {
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //         padding: 0;
    //     }
    // }

    @media (max-width: $smartphone) {
        .--1 {
            width: 50%;

            // .mask {
            //     @include aspect-ratio(350, 170);
            // }
        }

        // .--1,
        // .--2 {
        //     figure, img {
        //         position: absolute;
        //         left: 0;
        //         top: 0;
        //         width: 100%;
        //         height: 100%;
        //         object-fit: cover;
        //         padding: 0;
        //     }
        // }

        &.--inverted > div:first-child {
            display: flex;
            justify-content: flex-end;
        }
    }

    @media (min-width: $smartphone) {
        .--1 {
            width: rem(260);

            // .mask {
            //     @include aspect-ratio(260, 146);
            // }
        }

        .--2 {
            width: rem(540);
        }

        > div:first-child {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
