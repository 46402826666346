#Sidemenu {
    --bg: var(--black);
    --color: var(--white);
    --padding-v-top: #{rem(125)};
    --padding-v-bottom: #{rem(23)};
    --padding-h: var(--padding-xs);
}

#Sidemenu {
    background-color: var(--bg);
    color: var(--color);
    height: calc(var(--vh, 1vh) * 100);
    max-height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;

    padding: var(--padding-v-top) var(--padding-h) var(--padding-v-bottom);
    overflow-y: auto;

    @include z-index($z-index-sidemenu);
    @include font-text();

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    .top {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: var(--padding-l);

        .icon {
            margin-bottom: var(--padding-v-bottom);
            width: rem(320);
            height: auto;
            
            svg {
                fill: var(--color);
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }

    .toggle-menu {
        padding: 0;
        background-color: transparent;
    }

    a {
        @include basic-a();
        @include font-text();
        color: var(--color);
        height: calc(1.2 * 1em);
        display: inline-block;
    }

    
    .list {
        a {
            &[aria-current="page"] {
                font-weight: bold;
            }
            
            &.--is-active {
                font-weight: bold;
            }
        }
        
    }

    .info {
        margin-top: var(--padding-l);

        > div:not(:last-child) {
            margin-bottom: var(--padding-xs);
        }
    }
}