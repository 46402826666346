.block-about {
    --padding-v: var(--padding-xl-em);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
    }
}

.block-about {
    @include font-text();
    padding: var(--padding-v) var(--padding-h);

    &__text {
        margin-bottom: var(--padding-v);
         
        > * {
            max-width: rem(400);
        }
    }

    ul {
        li {
            display: flex;
        }
    }

    @media (max-width: $smartphone) {
        .spacer { display: none; }

        li {
            flex-direction: column;
            gap: var(--padding-xxs);

            &:nth-child(odd) {
                .key-benefit {
                    flex-direction: row-reverse;
                }
            }

            &:not(:last-child) {
                margin-bottom: var(--padding-s);
            }

            > *:not(:last-child) {
                margin-bottom: var(--padding-xs);
            }
        }
    }

    @media (min-width: $smartphone) {
        li {
            gap: var(--padding-xs);

            &:not(:last-child) {
                margin-bottom: var(--padding-m);
            }

            .key-benefit,
            .spacer {
                flex: 0 0 rem(260);
            }
        }

        .key-benefit,
        .spacer {
            &.--1 {
                order: 1;
            }

            &.--2 {
                order: 2;
            }

            &.--3 {
                order: 3;
            }

            &.--4 {
                order: 4;
            }

            &.--5 {
                order: 5;
            }
        }
    }
}
