.key-benefit {
    color: transparent;
    transition: opacity .1s;
    opacity: 0;
    position: relative;

    .img::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--pink);
        transition: background-color .3s ease-out;
        border: 1px solid transparent;
        @include z-index(1);
    }

    &.--loaded {
        opacity: 1;

        .title {
            color: var(--pink);
        }

        color: var(--primay-color);
    }

    &.--line {
        .img::before {
            border-color: var(--pink);
        }
    }

    &.--animated {
        .img::before {
            background-color: transparent;
        }
    }

    .img {
        width: 100%;
        position: relative;

        figure {
            @include aspect-ratio(1, 1);
            position: relative;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
        }

        &.--hover {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    
    @include isCursor() {
        .img.--hover {
            display: block;
            opacity: 0;
        }

        &:hover {
            .img {
                opacity: 0;
    
                &.--hover {
                    opacity: 1;
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        display: flex;
        gap:  var(--padding-xxs);
    
        .title {
            margin-bottom: var(--padding-xxxs);
        }

        .img {
            min-width: rem(80);
        }
    }
    
    @media (min-width: $smartphone) {
        .title {
            margin-top: var(--padding-xs);
            margin-bottom: var(--padding-xs);
        }
    }
}
