.block-texto-cuadrada-pequena-cuadrada {
    // .--1 {
    //     .mask {
    //         @include aspect-ratio(1, 1);
    //     }
    // }

    // .--1,
    // .--2 {
    //     figure, img {
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //         padding: 0;
    //     }
    // }

    @media (max-width: $smartphone) {
        .--1 + div {
            width: 50%;

            // .mask {
            //     @include aspect-ratio(350, 170);
            // }
        }

        &.--inverted > div:nth-child(3) {
            display: flex;
            justify-content: flex-end;
        }
    }

    @media (min-width: $smartphone) {
        flex-wrap: wrap;

        .--1 {
            flex: 0 0 rem(540);
        }
        
        .--1 + div {
            // display: flex;
            // align-items: flex-end;
            flex: 0 0 rem(260);
        }

        > div:first-child {
            flex: 0 0 100%;

            .text {
                width: rem(680);
            }
        }

        &.--inverted {
            > div:first-child {
                order: 4;
            }

            > div:nth-child(3) {
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
            }
        }
    }
}
