.block-billboard-photo {
    --height: 100vh;
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
    }
}

.block-billboard-photo {
    position: relative;
    color: var(--color);
    min-height: var(--height);
    @include font-text();

    &__text {
        padding: var(--padding-v) var(--padding-h);
        
        > * {
            max-width: rem(400);
        }
    }

    &__categories {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 0 var(--padding-h);

        a {
            @include basic-a();
            @include font-text();
            color: var(--light-grey);
            height: calc(1.2 * 1em);
            display: inline-block;
        }
    }

    .icon {
        display: block;
        width: rem(650);
        height: auto;
    }

    @media (max-width: $smartphone) {
        padding-top: calc(var(--padding-v) + var(--header-height));

        .icon {
            display: none;
        }  
        
        figure {
            video,
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    
    @media (min-width: $smartphone) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 90vh;
        position: relative;

        figure {
            video,
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        &__text {
            @include z-index($z-index-header);
            
            position: absolute; 
            top: 0;
            left: calc(50vw - var(--padding-h));
        }
    }
}
