.widget-team {
    --padding-v: var(--padding-xl-em);
    --padding-h: var(--padding-s);
    --gap: var(--padding-xs);
    --height: auto;
    --max-height: auto;
    --width-slide: #{rem(400)};

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xxs);
        --width-slide: 70vw;
    }
}

.widget-team {
    @include font-text();
    background-color: var(--white);
    color: var(--black);

    &__text {
        padding: var(--padding-v) var(--padding-h);
         
        > * {
            max-width: rem(400);
        }
    }

    &__slider {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: var(--padding-v);
        // padding-right: var(--padding-h);
        // padding-left: var(--padding-h);
        
        cursor: grab;    
        &:active {
            cursor: grabbing;
        }

        > .holder {
            order: 1;
            position: relative;
            height: var(--height);
            max-height: var(--max-height);
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
            gap: var(--gap);
        }
    }

    &__item {
        position: relative;
        flex: 0 0 var(--width-slide);
        width: var(--width-slide);
        height: 100%;
        overflow: hidden;

        svg {
            height: 100%;
            width: auto;
        }

        img {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }

        a {
            @include font-text();
            @include basic-a();
            color: var(--light-grey);
        }
        
        p {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: var(--padding-xxxs);

            &:first-child {
                margin-top: var(--padding-xs);
            }

            &:not(:last-child) {
                margin-bottom: var(--padding-xxxs);
            }
        }

        &:first-child,
        &:last-child {
            flex: 0 0 calc(var(--padding-h) - var(--gap));
            width: calc(var(--padding-h) - var(--gap));
        }
    }

    &__thumbnails {
        margin-bottom: var(--padding-xs);
        padding-right: var(--padding-h);
        padding-left: var(--padding-h);

        display: flex;
        justify-content: flex-start;
        gap: var(--padding-xxs);
    }

    &__thumbnail {
        height: var(--padding-s);
        width: var(--padding-s);
        position: relative;
        cursor: pointer;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            @include z-index(1);
        }

        &.--current::before {
            background-color: var(--pink);
        }
    }

    @media (max-width: $smartphone) {
        &__slider > .holder {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &__item {
            scroll-snap-align: start;
        }

        &__thumbnails {
            display: none;
        }
    }
}
