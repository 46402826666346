@use "sass:math";

:root {
    --y-header: 0;

    --padding-xxxl: #{math.div(360px, 16px) * 1rem};
    --padding-xxl: #{math.div(240px, 16px) * 1rem};
    --padding-xl: #{math.div(120px, 16px) * 1rem};
    --padding-l: #{math.div(60px, 16px) * 1rem};
    --padding-m: #{math.div(40px, 16px) * 1rem};
    --padding-s: #{math.div(30px, 16px) * 1rem};
    --padding-xs: #{math.div(20px, 16px) * 1rem};
    --padding-xxs: #{math.div(10px, 16px) * 1rem};
    --padding-xxxs: #{math.div(2px, 16px) * 1rem};

    --padding-xxxl-em: #{math.div(360px, 16px) * 1em};
    --padding-xxl-em: #{math.div(240px, 16px) * 1em};
    --padding-xl-em: #{math.div(120px, 16px) * 1em};
    --padding-l-em: #{math.div(60px, 16px) * 1em};
    --padding-m-em: #{math.div(40px, 16px) * 1em};
    --padding-s-em: #{math.div(30px, 16px) * 1em};
    --padding-xs-em: #{math.div(20px, 16px) * 1em};
    --padding-xxs-em: #{math.div(10px, 16px) * 1em};
    --padding-xxxs-em: #{math.div(2px, 16px) * 1em};

    --header-height: #{math.div(77px, 16px) * 1rem};
    --max-text-box: 800px;

    // --scrollbar-height: #{math.div(40px, 16px) * 1rem};
}

@media (max-width: $smartphone) {
    :root {
        --header-height: #{math.div(255px, 16px) * 1rem};
    }
}
