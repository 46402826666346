.block-blueprints {
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-s);
    --width-slide: 100%;
    --height: #{rem(600)};
    --bg: var(--primary-color);
    --color: var(--primary-bg);
    --index: 0;
    
    @media (max-width: $smartphone) {
        --height: #{rem(240)};
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
    }
}

.block-blueprints {
    width: var(--width);
    margin: var(--margin) 0;
    padding: 0;
    user-select: none;
    background: var(--bg);
    color: var(--color);
    padding: var(--padding-v) var(--padding-h);

    @include font-text();
    
    &__slider {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    
        > .holder {
            order: 1;
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
        }
    }

    &__item {
        position: relative;
        flex: 0 0 var(--width-slide);
        width: var(--width-slide);
        overflow: hidden;

        .info {
            margin-bottom: var(--padding-xs);
            display: flex;
            flex-direction: column;

            span:first-child {
                margin-bottom: var(--padding-xxxs);
            }
        }

        .img {
            height: var(--height);
            width: 100%;
            position: relative;
            overflow: hidden;
        
            svg {
                height: 100%;
                width: auto;
            }
        
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;
            }
        }
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        
        button {
            @include basic-a();
            @include font-text();
            pointer-events: all;
            background-color: transparent;
            padding: 0;
        }

        @include isCursor() {
            button:hover {
                color: var(--light-grey);
            }
        }

        .prev-next {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .info {
            > div {
                --lineHeight: calc(1.3 * 1em);
                overflow: hidden;
                height: var(--lineHeight);

                > div {
                    @include font-text(1);
                    display: block;
                    height: var(--lineHeight);
                    transform: translateY(calc(-1 * var(--index) * var(--lineHeight)));
                    // transform: translateY(calc(-1 * 3 * var(--lineHeight)));
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        &__controls {
            margin-top: var(--padding-xs);
        }
    }
    
    @media (min-width: $smartphone) {
        display: flex;
        flex-direction: column;

        &__controls {
            order: 1;
            margin-bottom: var(--padding-xs);
        }

        &__slider {
            order: 2;
        }
    }
}
