.block-2-pequenas-grande {
    > div:first-child {
        > div:first-child {
            margin-bottom: var(--gap);
        }
    }

    // .--1,
    // .--2 {
        // .mask {
        //     @include aspect-ratio(260, 260);
        // }
    // }

    // .--1,
    // .--2 {
    //     figure, img {
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //         padding: 0;
    //     }
    // }

    @media (max-width: $smartphone) {
        .--1,
        .--2 {
            width: 50%;
        }
    }

    @media (min-width: $smartphone) {
        .--1,
        .--2 {
            width: rem(260);
        }

        .--3 {
            width: rem(960);

            // .mask {
            //     @include aspect-ratio(960, 540);
            // }

            // figure, img {
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     width: 100%;
            //     height: 100%;
            //     object-fit: cover;
            //     padding: 0;
            // }
        }

        > div:first-child {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        
        > div {
            flex-grow: 0;
        }
        
        justify-content: flex-end;
    }
}
