.billboard-project {
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);
    --gap: var(--padding-xs);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-s);
    }
}

.billboard-project {
    position: relative;
    color: var(--color);
    @include font-text();

    h1 {
        @include font-text();
    }

    &__categories {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 0 var(--padding-h);
        margin-bottom: var(--padding-l);

        a {
            @include basic-a();
            @include font-text();
            color: var(--aux-color);
            height: calc(1.2 * 1em);
            display: inline-block;
        }

        [aria-current="page"] {
            color: var(--black);
        }
    }

    &__name {
        .links {
            a {
                @include basic-a();
                color: var(--aux-color);
                display: none;
            }
            
            @include isCursor() {
                a:hover {
                    color: var(--black);
                }
            }

            &.--press {
                .press { display: initial; }
            }
            
            &.--blueprints {
                .blueprints { display: initial; }
            }
        }
    }

    @media (max-width: $smartphone) {
        padding-top: calc(var(--padding-v) + var(--header-height));

        &__name,
        &__info {
            margin-bottom: var(--padding-m);
        }

        &__billboard {
            padding: 0 var(--padding-h);
        }

        &__content {
            padding: 0 var(--padding-h);
            display: flex;
            gap: var(--gap);

            > *:first-child {
                flex: 0 0 40%;
            }
        }

        .thumbnail {
            .mask {
                img {
                    width: 100%;
                    height: auto;
                }
            } 
        }
    }
    
    @media (min-width: $smartphone) {
        &__name {
            @include z-index($z-index-header);
            
            position: absolute; 
            top: 0;
            left: calc(50vw - var(--padding-h));
            background-color: var(--bg);
            padding: var(--padding-v) var(--padding-h);
        }
        
        &__info {
            position: relative; 
            left: rem(1010);
            margin-bottom: 0;
            padding: var(--padding-v) 0;
            min-height: var(--padding-xxxl);
        }

        &__name,
        &__info {
            > * {
                height: calc(var(--line-height-sans) * 1em);
            }
        }

        &__billboard {
            padding: 0 var(--padding-s);
            display: flex;
            flex-direction: row-reverse;
            align-items: stretch;
            gap: var(--gap);
            
            > div:last-child {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }

            p {
                max-width: var(--max-text-box);
            }

            .icon {
                display: block;
                width: rem(650);
                height: auto;
            }

            .thumbnail {
                flex: 0 0 rem(680);
    
                .mask {
                    @include aspect-ratio(680, 380);
    
                    * {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                } 
            }
        }
    }
}
